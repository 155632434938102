.App {
  background-color: #1e1e1e;
  color: #ffffff;
  min-height: 100vh;
}

.App-header {
  background-color: #d5c514;
  padding: 10px;
  text-align: center;
}

.App-content {
  display: flex;
}

.sidebar {
  width: 200px;
  background-color: #2d2d2d;
  padding: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 15px;
}

.sidebar a {
  color: #4CAF50;
  text-decoration: none;
}

.sidebar-divider {
  border: none;
  border-top: 1px solid #444;
  margin: 20px 0;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  border-left: 1px solid #444;
}

.dynamic-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.dynamic-table th,
.dynamic-table td {
  border: 1px solid #444;
  padding: 8px;
  text-align: left;
}

.dynamic-table th {
  background-color: #333;
  color: white;
}

.table-actions {
  margin-bottom: 20px;
}

/* button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
} */

.question-answer {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #2d2d2d;
  border-radius: 5px;
}

.question-answer h3 {
  margin-top: 0;
  color: #4CAF50;
}