@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-dark-bg text-dark-text;
}

.btn {
    @apply text-dark-text;
}

.input,
.select,
.textarea {
    @apply bg-dark-bg-alt text-dark-text;
}
